import * as React from "react"

import Layout from "../components/layout"
import * as styles from "../components/pages/contact.module.css"
import Seo from "../components/seo"


const Contact = () => (
  <Layout>
    <div className={styles.contact}>
      <h1 className={styles.title}>Contact</h1>
      <p className={styles.subintro}>Zin in een <span className={styles.stripethrough}>koffie</span> warme chocomelk, een babbel of heb je een vraag? Contacteer me. </p>
       <a href = "mailto: silke1janssens@hotmail.com" className={styles.email}><p className={styles.emailtekst}>silke1janssens@hotmail.com</p></a> 
      <a href="/CurriculumVitae_JanssensSilke.pdf" download className={styles.cv}><p className={styles.cvtekst}>Curriculum vitae</p></a>
    </div>
  </Layout>
)

export const Head = () => <Seo title="Contact" />

export default Contact


//misschien nog een soort ps waarom ik per abuis heet??
